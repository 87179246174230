/* eslint-disable class-methods-use-this */
import PCLNAnalytics from '@pcln/analytics'
import { ANALYTICS_CATEGORY_PACKAGES } from '@/constants/analytics'
import { IGoogleAnalyticsFourMarketingTracking } from '@pcln/contentful-components'
import {
  type GA4AbnormalSearchEvent,
  type GA4TripActivity,
  type GA4BundlePromotionEvent,
  type GA4InternalEvent,
  type GA4MarketingPromotionEvent,
  type GA4PackageCarousel,
  type GA4PackageFormLink,
  type GA4SearchForm,
  type GA4SelectContent,
  type GA4SignIn,
  type GA4PennyTypeahead,
  type GA4PennyIconDisplay,
  type GA4PennyTooltipDisplay,
  type GA4PennyTooltipClick,
  type GA4PennyModalOpened,
  type GA4PageViewEvent
} from '@/ga4types'

type IGoogleAnalyticsFourMarketingTrackingNoContentId = Omit<
  IGoogleAnalyticsFourMarketingTracking,
  'contentTypeId'
>

type GA4Event =
  | GA4SignIn
  | GA4PackageFormLink
  | GA4BundlePromotionEvent
  | GA4PackageCarousel
  | GA4TripActivity
  | GA4InternalEvent
  | GA4SearchForm
  | GA4SelectContent
  | GA4MarketingPromotionEvent
  | GA4AbnormalSearchEvent
  | GA4PennyTypeahead
  | GA4PennyIconDisplay
  | GA4PennyTooltipDisplay
  | GA4PennyTooltipClick
  | GA4PennyModalOpened
  | GA4PageViewEvent
  | IGoogleAnalyticsFourMarketingTrackingNoContentId

type LogData = {
  message: string
  appName?: string
  appVersion?: string
  cguid?: string
  error?: unknown
  requestUrl?: string
  requestBody?: unknown
  requestMethod?: string
  responseUrl?: string
  status?: number
  statusText?: string
  type?: string
} & Record<string, string | boolean | number | unknown>

function initializePCLNAnalytics() {
  PCLNAnalytics.appName = 'next-landing'
  PCLNAnalytics.appVersion = 'unknown'
}

function fireGA4EventFromBrowser(ga4Props: Readonly<GA4Event>) {
  if (
    window?.dataLayer &&
    (ga4Props.event === 'select_promotion' ||
      ga4Props.event === 'view_promotion')
  ) {
    window.dataLayer.push({ ecommerce: null })
  }
}

class Analytics {
  fireEvent({
    category = ANALYTICS_CATEGORY_PACKAGES,
    action = '',
    data = '',
    event = '',
    label = ''
  }) {
    initializePCLNAnalytics()
    PCLNAnalytics.logEvent({
      action,
      category,
      data,
      event,
      label
    })
  }

  fireGA4Event(ga4Props: Readonly<GA4Event>) {
    try {
      initializePCLNAnalytics()
      PCLNAnalytics.GA4Enabled = true
      fireGA4EventFromBrowser(ga4Props)
      PCLNAnalytics.logEvent(ga4Props)
    } finally {
      PCLNAnalytics.GA4Enabled = false
    }
  }

  // Log an error
  // sample query 'index=cheetah sourcetype=ESBROWSEREVENTDATA_ESSVCS action=Error'
  logError(logData: LogData) {
    initializePCLNAnalytics()
    PCLNAnalytics.logError({
      ...logData
    })
  }

  // Log directly to Splunk
  // sample query 'index=cheetah sourcetype=ESBROWSEREVENTDATA_ESSVCS action=Log'
  log({ message = '', ...rest }) {
    initializePCLNAnalytics()
    PCLNAnalytics.log({
      message,
      ...rest
    })
  }

  // Log a warning
  // sample query 'index=cheetah sourcetype=ESBROWSEREVENTDATA_ESSVCS action=warning'
  logWarning({ name = '', message = '' }) {
    initializePCLNAnalytics()
    PCLNAnalytics.logWarning({
      name,
      message
    })
  }
}

export default new Analytics()
